.users {
  td.status {
    text-align: right;

    .badge-two-factor {
      color: $state-success-text;
      background-color: $state-success-bg;
      margin-right: 5px;
    }

    .fa-check {
      color: $emerald;
    }

    .glyphicon-lock {
      color: $pomegranate;
    }

    .fa-envelope {
      color: $orange;
    }

    .person-actions button {
      vertical-align: inherit;
    }
  }

  .user-actions {
    display: inline-block;

    li {
      a.disabled {
        color: $btn-link-disabled-color;
        pointer-events: none;
      }

      .two-factor-disable {
        color: $brand-danger;
      }
    }
  }
}

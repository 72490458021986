.attachment {
  img.thumbnail {
    max-width: 64px;
    max-height: 64px;
    display: inline-block;
  }
  display: inline-block;
}

tr.candidate_attachment .td:last-child { width: 28px; }

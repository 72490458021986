.navbar-brand {
  padding: 15px !important;
  .logo {
      height: 24px;
  }
}

.navbar.navbar-fixed-top + .page-container {
  margin-top: 0 !important;
  .main-content {
    padding-top: 90px;
    .page-title {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.login-page { padding-top: 70px; }

@media (max-width: 1100px) {
  .navbar-brand {
    display: none !important;

  }
  .navbar-nav i.fa {
    display: none !important;
  }
}

input[type="radio"] {
  width: inherit;
}

.label {
  display: block;
  &.inline-label { display: inline-block !important; }
}

.nav.nav-tabs > li > a.info {
  background: #ffffff;
  cursor: text;
}

.page-body, .page-container {
  height: auto;
}

.page-title {
  .avatar {
    width: 35px;
    height: 35px;
    margin-top: -6px;
  }
  .title-env {
    .title + p {
      margin-top: 8px !important;
    }
  }
  .breadcrumb-env {
    .label {
      font-size: 1.2rem;
      line-height: 2rem;
    }
  }
}

.form-horizontal .form-group {
  margin-top: -5px;
  .control-label {
    text-align: left;
    font-weight: 700;
  }
}
.form-group {
  input[type=checkbox] {
    margin-left: 0px;
  }
}

//http://xcellerant.net/2013/12/30/resizing-a-bootstrap-3-modal/
.modal-content {
  width: 900px;
  margin-left: -150px;
}

.alert {
  .btn {
    margin-bottom: 0px;
    margin-left: 10px;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
}

.btn-disabled {
  background-color: #efefef;
  border: 1px solid #ccc;
}

.btn-no-color {
  color: inherit;
  background-color: inherit;
  border-color: none;
}

.title-env {
  .btn {
    display: inline-block;
    margin-right: 10px;
  }
  h1 {
    display: inline-block;
  }
}

ol.breadcrumb {
  font-size: 12px;
  margin-bottom: 10px;
  padding-left: 30px;
  li a:hover {
    text-decoration: underline;
    color: $link-color;
  }
}

li.select2-search-choice.all-providers {
  margin-left: -15px !important;
}

.panel.panel-color .panel-heading.panel-heading-xs {
  padding-top: 8px;
  padding-bottom: 8px;
}

.js-form-reset {
  margin-top: -3px;
  margin-bottom: -3px;
  padding-top: 7px;
  padding-bottom: 5px;
}

.dropdown-menu.notifications {
  margin-top: -20px !important;
  width: 500px !important;
}

td .actions .btn.btn-xs {
  margin-top: -3px;
  margin-bottom: -2px;
}

.modal-body {
  select {
    width: 100%;
  }
}

textarea {
  width: 100%;
}

.panel-options {
  .btn {
    margin: 0px !important;
    color: #ffffff !important;
  }
}

.table {
  th { white-space: nowrap; }
}

.table-responsive {
  border: none;
}

.placeholder { color: #999; }

.visible { visibility: visible !important; }

.navbar {
  .services form .btn-link {
    color: $link-color;
    font-weight: 600;
  }
}

@media screen and (max-width: 768px) {
  .main-content { padding-top: 50px !important; }

  .navbar-brand {
    display: block !important;
    margin-top: -15px !important;
    margin-bottom: -20px !important;
  }
}

.navbar.horizontal-menu .navbar-inner .navbar-nav>li>a{
  padding-left: 10px;
  padding-right: 10px;
}

.tooltip-inner {
  white-space: pre-wrap;
}

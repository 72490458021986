.rotors {
  .rotors-container {
    .panel-body {
      padding-top: 0px;
    }
    .rotor-container {
      padding: 20px 0px;
      border-bottom: 1px solid $clouds;
    }
  }
}

table.table {
  .form-group {
    .form-control { width: 80% }
  }

  td {
    .badge { margin-bottom: 8px; }
  }

  tr.new {
    color: #d5080f;

    a, a:visited{
      color: inherit;
    }
  }

  .borderless { border: none !important; }
}

// bootstrap 3 does not support submenus, so https://stackoverflow.com/a/18024991
.dropdown-menu .dropdown-submenu {
  position: relative;
  & > .dropdown-menu {
    top:0;
    left:100%;
    margin-top:-6px;
    margin-left:-1px;
    -webkit-border-radius:0 6px 6px 6px;
    -moz-border-radius:0 6px 6px 6px;
    border-radius:0 6px 6px 6px;
  }
  & > a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #cccccc;
    margin-top: 5px;
    margin-right: -10px;
  }
  &.pull-left {
    float:none !important;
    & > .dropdown-menu {
      left:-100%;
      margin-left:10px;
      -webkit-border-radius:6px 0 6px 6px;
      -moz-border-radius:6px 0 6px 6px;
      border-radius:6px 0 6px 6px;
    }
  }
  &:hover > .dropdown-menu { display:block; }
  &:hover > a:after { border-left-color: #ffffff; }
}

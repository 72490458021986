.modal-content {
  .upload, .api-upload {
    a.modal-link {
      display: none !important;
    }
  }
}

.api_upload_batches {
  .status-pending, .status-generating { color: $state-warning-text; }
  .status-generated { color: $state-success-text; }
  .status-failed { color: $state-danger-text; }
}

.widgets-container {
  .scrollable-widget {
    max-height: 250px;
    overflow: scroll;
  }
  .scrollable-widget-lg {
    max-height: 500px;
    overflow: scroll;
  }
  .widget {
    .panel-body {
      padding: 0;
      min-height: 50px;
      .fa-spin { margin: 10px 0; }
      .widget-container {
        .well-sm {
          border: 0;
          border-radius: 0;
          margin-bottom: 0;
        }
        table { margin-top: 10px; }
      }
      .summary {
        .label { display: inline !important; }
      }
      .table {
        font-size: 13px;
        margin: 0;
        overflow: hidden;
        .btn {
          padding: 0;
          white-space: normal;
          font-weight: bold;
          text-align: left;
        }
        th {
          vertical-align: middle;
          &:not(:first-child) { text-align: center; }
        }
        td {
          text-align: center;
          vertical-align: middle;
          .btn {
            font-size: 13px !important;
            font-weight: normal;
          }
        }
        &.compliance_warnings, &.ccc_compliance_warnings {
          th, td { text-align: left; }
        }
      }
    }
  }
}

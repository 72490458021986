body.pages {

  h3, h4, h5 {
    font-weight: bold;
  }
  dl.indented dd {
    margin-left: 2.5em;
    margin-bottom: 0.5em;
  }
  dl.indented, pre {
    margin-top: 1em;
  }
  .panel-body ul {
    padding-left: 0px;
    li { margin-top: 1em; }
  }

}

.login-container {
  h4 { text-align: center; }
  .content-login {
    .form-group { margin: 20px 0; }
  }
}

.list-group.identity-providers {
  border-top: 1px dotted #bdc3c7;
  margin-top: 30px;
  margin-bottom: 0;
  padding-top: 10px;
  text-align: center;

  .list-group-item {
    border: none;
    height: 48px;
    padding: 0;
    img {
      margin-right: 8px;
      max-height: 48px;
    }
    .omniauth-sign-in-label { display: none; }
  }
}

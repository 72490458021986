.shifts, .ccc_shifts {
  table {
    th {
      &:first-child { width: 30px; }
      &.state { width: 20px; }
      &.candidate-action { width: 80px; }
    }
    td {
      .fa.text-warning { margin-left: 5px; }
      .badge {
        vertical-align: text-top;
        margin-right: 5px;
      }
    }
  }
}

// TODO: change to just `.bulk-edit-footer`
#shifts-bulk-edit-footer,
#preferences-bulk-edit-footer, #bulk-edit-footer {
  position: fixed;
  bottom: -50px;
  //start hidden
  z-index: 9999;
  height: 50px;
  background-color: #ccc;
  border-top: 1px solid #BBBBBB;
  color: #FFFFFF;
  width: 100%;
  margin-left: -30px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 35px;
  textarea {
    color: #333;
    line-height: 1.1em;
    width: 100% !important;
  }
}

table.timesheets {
  input {
    width: 100%;
  }
  tr {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    &.hover {
      background-color: #f1f1f1;
      border-left: 5px solid $link-color;
      border-right: 5px solid $link-color;
    }
  }
}

.comment {
  display: block;
  font-size: 0.9em;
  hr{
    margin-top: 0px !important;
  }
}

.timesheet-anomalies {
  i.fa { margin-left: 3px; }
}

@media screen and (max-width: 768px) {
  #preferences-bulk-edit-footer{
    &.active{
      height: 100px;
      text-align: left;
      .btn{
        padding-left: 4px;
        padding-right: 4px;
      }
      .close{
        position: absolute;
        right: 10px;
      }

    }
  }
}

.crew-requirement {
  .paramedic-level, .grade {
    color: #ffffff;
    font-weight: 700;
    font-size: $font-size-small;
    padding: 5px;
    &.danger {
      background: #cc3f44;
    }
    &.success {
      background: #8dc63f;
    }
    &.warning {
      background: #ffba00;
    }
  }
  .box {
    border: 1px dashed #ccc;
    padding-top: 100px;
    height: 288px;
  }
  .no-candidate, .candidate-assigned {
    border: 1px dashed #ccc;
    padding-top: 100px;
    min-height: 288px;
    .btn {
      margin-top: 10px;
    }
  }
  .candidate{
    position: relative;
    border: 1px dashed #ccc;
    min-height: 288px;
    padding-top: 40px;
    .avatar{
      width: 60px;
      height: 60px;
      margin-bottom: 20px;
    }
    .details{
      font-size: 0.9em;
      margin-bottom: 20px;
    }
    .timesheet{
      margin: auto;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
}

.worker-search {
  border: 1px dashed #ccc;
  padding-top: 0px;
  display: none;
  .fa-spin-container {
    padding: 10px;
  }
  .load-results {
    .candidate {
      text-align: left;
      .avatar {
        width: 30px;
        height: 30px;
        margin-right: 5px;
      }
      .name span {
        vertical-align: top;
        font-weight: 500;
      }
      .details { font-size: 0.8em; }
      .btn { margin-top: 5px; }
      form {
        margin-top: 5px;
        background: none !important;
      }
      form.wtd_candidate_form {
        background: none !important;
        display: none;
      }
    }
  }
}

.unpublished {
  background-color: #ff0000;
  color: #ffffff;
}

.published {
  background-color: #40bbea;
  color: #ffffff;
}

.filled {
  background-color: #8dc63f;
  color: #ffffff;
}

.partially_filled {
  background-color: #ffba00;
  color: #ffffff;
}

#search-results.roster{
  width: 100%;
  overflow-x: auto;
  padding-bottom: 40px;
  margin-top: 40px;
}
.shift-roster {
  thead{
    tr { border-bottom: 2px solid; }
  }
  td { min-width: 150px; }
  width: 100%;
  .day {
    text-align: center;
    font-size: 1.2rem;
    color: #666666;
    min-width: 80px;
  }
  .weekend{
    background-color: #f5f5f5;
  }
  .roster-shift {
    display: table;
    margin: 4px auto;
    color: #333;
    border: 1px solid #ccc;
    width: 120px;
    .shift-times {
      font-size: 1.1rem;
      text-align: center;
      //float: left;
      //margin-left: 10px;
      margin-top: 5px;
      margin-bottom: 5px;
      span {
        display: block;
      }
    }
    .shift-vehicle {
      font-size: 1rem;
      //float: right;

      margin-top: 10px;
      text-align: center;
    }
    .shift-status {
      font-size: 1rem;
      text-align: center;
      clear: both;
    }
  }
  a:link, a:active, a:visited{color: inherit}
  a:hover{
    color: $link-color;
  }
}
